<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Land'" />
              </b-col>
            </b-row> -->
            <p class="text-muted font-13 mb-0"></p>
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/driver`" title="Driver" :columns="columns"
                routerpath="/master/crm/adddriver" :formOptions="formOptions" :newPage="true" :edit="edit" :add="add"
                :canDelete="canDelete" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import TabPosition from "../../../../components/TabView.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: "Username",
          field: "username",
          filterOptions: {
            enabled: true,
            placeholder: "Search Username",
          },
        },
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search name",
          },
        },
        {
          label: "Mobile No",
          field: "mobile",
          filterOptions: {
            enabled: true,
            placeholder: "Search Mobile No",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email",
          },
        },
        {
          label: "Joining Date",
          field: "joiningdate",
          type: 'datetime',
          filterOptions: {
            enabled: true,
            placeholder: "Search Joining Date",
          },
        },
        {
          label: "Licence No",
          field: "licence",
          filterOptions: {
            enabled: true,
            placeholder: "Search Joining Date",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        submitRouterPath: "/master/crm/driver",
        formTitle: "Add Driver",
        gridForm: true,
        method: "post",
        action: "add",
        getEditValue: `${baseApi}/getusersbyid`,
        url: `${baseApi}/driver`,
        inputFields: [
          {
            label: "Password",
            name: "password",
            value: "",
            type: "password",
            placeholder: "Enter Password",
            class: "col-md-6",
            required: true,
          },
        ],
      },
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Driver") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
